/* eslint-disable no-underscore-dangle */
import { parseSuccessfulResponse, RequestSender } from "./helpers";
import { GoogleCourse } from "./model";
import parseGoogleCourse from "./parseGoogleCourse";

async function googleCourses(
  request: RequestSender,
): Promise<ReadonlyArray<GoogleCourse>> {
  const response = await request("get", "/google-courses");
  return parseSuccessfulResponse(response, (data) => {
    if (!Array.isArray(data)) {
      throw new Error("Not array");
    }
    return data.map(parseGoogleCourse);
  });
}

export default googleCourses;
