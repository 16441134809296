import { LoginType } from "./backendApi/model";

type UserType = "parent" | "student" | "teacher";

const allUserTypes: ReadonlyArray<UserType> = ["parent", "student", "teacher"];

type User = {
  // readonly id: string;
  readonly type: UserType;
  readonly loginType: LoginType;
  readonly username: string;
  readonly emailAddress?: string;
  readonly authToken: string;
};

function isValidUser(user: any): user is User {
  return (
    typeof user === "object" &&
    !!user &&
    // typeof user.id === "string" &&
    allUserTypes.includes(user.type) &&
    typeof user.loginType === "object" &&
    !!user.loginType &&
    ["google", "clever", "email"].includes(user.loginType.type) &&
    typeof user.username === "string" &&
    typeof user.authToken === "string"
  );
}

type PageQueryProps<TData, TPageContext = never> = {
  readonly data: TData;
  readonly pageContext: TPageContext;
  readonly location: {
    readonly href: string;
  };
};

type DataConnection<T> = {
  readonly edges: ReadonlyArray<{
    readonly node: T;
  }>;
};

type RemarkDocument<T> = {
  readonly id: string;
  readonly html: string;
  readonly frontmatter: T;
};

type RemarkFile<T> = {
  readonly childMarkdownRemark: RemarkDocument<T>;
};

function isSubscribableUser(user: Pick<User, "type" | "loginType">) {
  return user.type === "parent" && user.loginType.type === "email";
}

export { isValidUser, isSubscribableUser };
export type {
  UserType,
  RemarkFile,
  User,
  DataConnection,
  RemarkDocument,
  PageQueryProps,
};
