import { isAxiosErrorWithMessage, RequestSender } from "./helpers";

async function resetPassword(
  request: RequestSender,
  token: string,
  code: string,
  password: string,
): Promise<void> {
  try {
    await request<any>(
      "post",
      "/auth/password/reset",
      {
        nOTP: code,
        sPassword: password,
      },
      {
        headers: {
          sVerificationToken: token,
        },
      },
    );
  } catch (e) {
    if (isAxiosErrorWithMessage(e, 403, "Wrong otp")) {
      throw new Error("Incorrect code");
    }
    throw e;
  }
}

export default resetPassword;
