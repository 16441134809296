import { parseSuccessfulResponse, RequestSender } from "./helpers";
import { StudentInput, studentInputToApiData } from "./studentInput";

async function createStudent(request: RequestSender, input: StudentInput) {
  const response = await request(
    "post",
    "/student/create",
    studentInputToApiData(input),
  );
  if (response.data.message !== "success") {
    throw new Error(response.data.message);
  }
  return parseSuccessfulResponse(response, (d): string => d._id);
}

export default createStudent;
