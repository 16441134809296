/* eslint-disable no-underscore-dangle */
import { parseSuccessfulResponse, RequestSender } from "./helpers";
import parseCleverSection from "./parseCleverSection";

async function getCleverSection(request: RequestSender, id: string) {
  const response = await request("get", `/clever-sections/${id}`);
  return parseSuccessfulResponse(response, parseCleverSection);
}

export default getCleverSection;
