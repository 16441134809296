/* eslint-disable no-underscore-dangle */
import { parseSuccessfulResponse, RequestSender } from "./helpers";
import { Mathematician } from "./model";

async function mathematicians(
  request: RequestSender,
): Promise<ReadonlyArray<Mathematician>> {
  const response = await request("get", "/mathematician/list");
  return parseSuccessfulResponse(response, (data) => {
    if (!Array.isArray(data)) {
      throw new Error("Not array");
    }
    return data.map((raw) => ({
      id: raw._id,
      name: raw.sName,
    }));
  });
}

export default mathematicians;
