import { RequestSender } from "./helpers";
import { StudentInput } from "./studentInput";

type EditStudentInput = Omit<StudentInput, "username" | "password">;

async function editStudent(
  request: RequestSender,
  id: string,
  input: EditStudentInput,
): Promise<void> {
  const response = await request("post", `/student/edit/${id}`, {
    sFirstName: input.firstName,
    sMonthOfBirth: input.monthOfBirth,
    sYearOfBirth: input.yearOfBirth,
  });
  if (response.data.message !== "updated") {
    throw new Error(response.data.message);
  }
}

export type { EditStudentInput };
export default editStudent;
