import axios, { AxiosResponse, Method } from "axios";
import partial from "lodash/partial";
import {
  isAxiosErrorWithMessage,
  isStandardServerPayload,
  StandardServerPayload,
} from "./helpers";
import login from "./login";
import loginClever from "./loginClever";
import loginGoogle from "./loginGoogle";
import signUp from "./signUp";
import verifyCode from "./verifyCode";
import students from "./students";
import cleverSections from "./cleverSections";
import googleCourses from "./googleCourses";
import getCleverSection from "./getCleverSection";
import getGoogleCourse from "./getGoogleCourse";
import cleverSectionStudents from "./cleverSectionStudents";
import googleCourseStudents from "./googleCourseStudents";
import removeStudentFromSubscription from "./removeStudentFromSubscription";
import subscriptionPrices from "./subscriptionPrices";
import getStudent from "./getStudent";
import getProfile from "./getProfile";
import updateSubscription from "./updateSubscription";
import changePassword from "./changePassword";
import changeStudentPassword from "./changeStudentPassword";
import checkUserName from "./checkUserName";
import createStudent from "./createStudent";
import createContact from "./createContact";
import setCleverSectionEnabled from "./setCleverSectionEnabled";
import setGoogleCourseEnabled from "./setGoogleCourseEnabled";
import createPortalSession from "./createPortalSession";
import mathematicians from "./mathematicians";
import deleteStudent from "./deleteStudent";
import editStudent from "./editStudent";
import resendOtp from "./resendOtp";
import resendOtpLink from "./resendOtpLink";
import forgotPassword from "./forgotPassword";
import createPromotionsViews from "./createPromotionsViews";
import resetPassword from "./resetPassword";
import getStudentGameTimes from "./getStudentGameTimes";
import getMyGameTimes from "./getMyGameTimes";
import logout from "./logout";
import accessPrices from "./accessPrices";
import createPaymentCheckoutSession from "./createPaymentCheckoutSession";
import deleteProfile from "./deleteProfile";
import createFreeTrialForStudent from "./createFreeTrialForStudent";

function isUnauthorizedError(error: unknown): boolean {
  return isAxiosErrorWithMessage(error, 401, "unauthorised");
}

function createApi(
  baseUrl: string,
  onUnauthorized: () => void,
  authToken?: string,
) {
  const client = axios.create({
    baseURL: baseUrl,
    timeout: 20000,
    headers: authToken
      ? {
          authorization: authToken,
        }
      : undefined,
  });

  const request = async <T>(
    method: Method,
    path: string,
    data?: any,
    options?: { headers?: Record<string, string> },
  ): Promise<AxiosResponse<StandardServerPayload<T>>> => {
    try {
      const response = await client.request({
        url: path,
        method,
        data,
        headers: {
          "Content-Type": "application/json",
          ...options?.headers,
        },
      });
      if (response.status !== 204 && !isStandardServerPayload(response.data)) {
        throw new Error("Invalid server response");
      }
      return response;
    } catch (e) {
      if (isUnauthorizedError(e)) {
        onUnauthorized();
      }
      throw e;
    }
  };

  return {
    removeStudentFromSubscription: partial(
      removeStudentFromSubscription,
      request,
    ),
    createContact: partial(createContact, request),
    createPaymentCheckoutSession: partial(
      createPaymentCheckoutSession,
      request,
    ),
    createPortalSession: partial(createPortalSession, request),
    loginClever: partial(loginClever, request),
    loginGoogle: partial(loginGoogle, request),
    resetPassword: partial(resetPassword, request),
    forgotPassword: partial(forgotPassword, request),
    resendOtp: partial(resendOtp, request),
    resendOtpLink: partial(resendOtpLink, request),
    createFreeTrialForStudent: partial(createFreeTrialForStudent, request),
    verifyCode: partial(verifyCode, request),
    subscriptionPrices: partial(subscriptionPrices, request),
    accessPrices: partial(accessPrices, request),
    signUp: partial(signUp, request),
    login: partial(login, request),
    students: partial(students, request),
    getStudent: partial(getStudent, request),
    getProfile: partial(getProfile, request),
    deleteProfile: partial(deleteProfile, request),
    updateSubscription: partial(updateSubscription, request),
    changePassword: partial(changePassword, request),
    changeStudentPassword: partial(changeStudentPassword, request),
    checkUserName: partial(checkUserName, request),
    setCleverSectionEnabled: partial(setCleverSectionEnabled, request),
    setGoogleCourseEnabled: partial(setGoogleCourseEnabled, request),
    cleverSections: partial(cleverSections, request),
    googleCourses: partial(googleCourses, request),
    getCleverSection: partial(getCleverSection, request),
    getGoogleCourse: partial(getGoogleCourse, request),
    cleverSectionStudents: partial(cleverSectionStudents, request),
    googleCourseStudents: partial(googleCourseStudents, request),
    createStudent: partial(createStudent, request),
    mathematicians: partial(mathematicians, request),
    deleteStudent: partial(deleteStudent, request),
    editStudent: partial(editStudent, request),
    createPromotionsViews: partial(createPromotionsViews, request),
    getStudentGameTimes: partial(getStudentGameTimes, request),
    getMyGameTimes: partial(getMyGameTimes, request),
    logout: partial(logout, request),
  };
}

type ApiClient = ReturnType<typeof createApi>;

export { isAccountInactiveError, isEmailAddressExistsError } from "./signUp";
export type { PaymentCheckoutSessionInput } from "./createPaymentCheckoutSession";
export type { SubscriptionPrice } from "./subscriptionPrices";
export type { AccessPrice } from "./accessPrices";
export type { CreatePortalSessionInput } from "./createPortalSession";
export type { UpdateSubscriptionInput } from "./updateSubscription";
export { createApi };
export type { ApiClient };
