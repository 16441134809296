import { isAxiosErrorWithMessage, RequestSender } from "./helpers";
import { VerifyLoginResponse } from "./model";

function isInvalidCodeError(error: unknown): boolean {
  return isAxiosErrorWithMessage(error, 403, "Wrong otp");
}

function isExpiredCodeError(error: unknown): boolean {
  return isAxiosErrorWithMessage(error, 417, "OTP expired");
}

async function verifyCode(
  request: RequestSender,
  verificationCode: string,
  otpCode: string,
): Promise<VerifyLoginResponse> {
  try {
    const response = await request<any>(
      "get",
      `/auth/otp/verify/${otpCode}`,
      undefined,
      {
        headers: {
          sverificationtoken: verificationCode,
        },
      },
    );
    return {
      id: response.data.data.id,
      username: response.data.data.sUserName,
      emailAddress: response.data.data.sEmail,
      userType: response.data.data.eUserType,
      loginType: {
        type: response.data.data.oLoginType.eType,
      },
      authToken: response.headers.authorization,
    };
  } catch (e) {
    if (isInvalidCodeError(e)) {
      throw new Error("Invalid code");
    }
    if (isExpiredCodeError(e)) {
      throw new Error("Code has expired");
    }
    throw e;
  }
}

export default verifyCode;
