import axios, { AxiosResponse, Method } from "axios";

type StandardServerPayload<T> = {
  readonly data?: T;
  readonly message: string;
};

function isStandardServerPayload<T>(
  payload: any,
): payload is StandardServerPayload<T> {
  return (
    typeof payload === "object" &&
    payload &&
    "message" in payload &&
    typeof payload.message === "string"
  );
}

function getStandardServerError(error: unknown) {
  if (!axios.isAxiosError(error) || !error.response) {
    return undefined;
  }

  const { data } = error.response;
  if (!isStandardServerPayload(data)) {
    return undefined;
  }

  return data.message;
}

function isAxiosErrorWithMessage(
  error: unknown,
  statusCode: number,
  message: string,
): boolean {
  if (!axios.isAxiosError(error)) {
    return false;
  }
  if (error.response?.status !== statusCode) {
    return false;
  }
  const { data } = error.response;
  if (!isStandardServerPayload(data)) {
    return false;
  }
  return data.message === message;
}

function parseSuccessfulResponse<T>(
  response: AxiosResponse<unknown>,
  parse: (data: any) => T,
): T {
  if (!response.status.toString().startsWith("20")) {
    throw new Error("Error communicating server");
  }
  const { data } = response;
  if (!isStandardServerPayload(data)) {
    throw new Error("Invalid response from server");
  }
  return parse(data.data);
}

type RequestSender = <T>(
  method: Method,
  path: string,
  data?: any,
  options?: { headers?: Record<string, string> },
) => Promise<AxiosResponse<StandardServerPayload<T>>>;

export type { RequestSender, StandardServerPayload };
export {
  isAxiosErrorWithMessage,
  isStandardServerPayload,
  parseSuccessfulResponse,
  getStandardServerError,
};
