import { parseSuccessfulResponse, RequestSender } from "./helpers";

type AccessPrice = {
  readonly lookupKey: string;
  readonly price: number;
  readonly numMonths: number;
};

async function accessPrices(
  request: RequestSender,
): Promise<ReadonlyArray<AccessPrice>> {
  const response = await request("get", `/payment/access-prices`);
  return parseSuccessfulResponse(response, (d) => d);
}

export type { AccessPrice };
export default accessPrices;
