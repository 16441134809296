import { isAxiosErrorWithMessage, RequestSender } from "./helpers";

function isAccountInactiveError(error: unknown): boolean {
  return isAxiosErrorWithMessage(error, 403, "Account inactive");
}

async function resendOtp(
  request: RequestSender,
  emailAddress: string,
): Promise<string> {
  const response = await request<any>("post", "/auth/otp/send", {
    sEmail: emailAddress,
  });
  return response.headers.sverificationtoken;
}

export { isAccountInactiveError };
export default resendOtp;
