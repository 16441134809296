import { RequestSender } from "./helpers";

async function removeStudentFromSubscription(
  request: RequestSender,
  studentId: string,
): Promise<void> {
  const response = await request(
    "post",
    `/profile/remove-student-from-subscription`,
    { studentId },
  );
  if (response.data.message !== "success") {
    throw new Error(response.data.message);
  }
}

export default removeStudentFromSubscription;
