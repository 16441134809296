import { RequestSender } from "./helpers";

type CreatePortalSessionInput = {
  readonly returnUrl: string;
};

async function createPortalSession(
  request: RequestSender,
  input: CreatePortalSessionInput,
): Promise<string> {
  const response = await request<{ redirectUrl: string }>(
    "post",
    `/profile/portal-sessions`,
    input,
  );
  if (response.data.message !== "success") {
    throw new Error(response.data.message);
  }
  if (!response.data.data) {
    throw new Error("Invalid response");
  }
  return response.data.data.redirectUrl;
}

export type { CreatePortalSessionInput };
export default createPortalSession;
