import { parseSuccessfulResponse, RequestSender } from "./helpers";

type UpdateSubscriptionInput = {
  readonly studentIds: ReadonlyArray<string>;
};

async function updateSubscription(
  request: RequestSender,
  input: UpdateSubscriptionInput,
): Promise<void> {
  const response = await request("put", `/profile/subscription`, input);
  return parseSuccessfulResponse(response, (d) => d);
}

export type { UpdateSubscriptionInput };
export default updateSubscription;
