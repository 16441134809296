function parseCleverSection(raw: any) {
  return {
    id: raw._id,
    name: raw.sName,
    subject: raw.sSubject,
    grade: raw.sGrade,
    enabled: raw.bEnabled,
  };
}

export default parseCleverSection;
