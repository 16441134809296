import React, { ReactNode } from "react";
import clsx from "clsx";
import "./Button.css";

type BaseButtonProps = {
  readonly colour: "blue" | "red" | "green" | "white";
  readonly size: "small" | "large";
  readonly variant: "outline" | "solid";
  readonly className?: string;
  readonly disabled?: boolean;
  readonly children: ReactNode;
};

type ButtonButtonProps = BaseButtonProps & {
  readonly type: "button";
  readonly onClick?: () => void;
};

type SubmitButtonProps = BaseButtonProps & {
  readonly type: "submit";
};

type ButtonProps = ButtonButtonProps | SubmitButtonProps;

function buttonClassName({
  colour,
  size,
  variant,
  className,
}: Pick<ButtonProps, "colour" | "size" | "variant" | "className">): string {
  return clsx(`btn btn-${colour} btn-${size} btn-${variant}`, className);
}

function Button({
  children,
  className,
  disabled,
  size,
  variant,
  colour,
  ...props
}: ButtonProps) {
  return (
    <button
      type={props.type === "submit" ? "submit" : "button"}
      className={buttonClassName({ colour, size, variant, className })}
      disabled={disabled}
      onClick={props.type === "button" ? props.onClick : undefined}
    >
      {children}
    </button>
  );
}

export { buttonClassName };
export type { ButtonProps };
export default Button;
