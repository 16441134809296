import axios, { AxiosResponse } from "axios";
import { getStandardServerError, RequestSender } from "./helpers";
import { ExternalLoginResponse } from "./model";

function isNotLinkedToAnyClassesResponse(response: AxiosResponse) {
  if (response.status !== 403) {
    return false;
  }
  try {
    const body = response.data;
    return (
      typeof body === "object" &&
      !!body &&
      (body as Record<string, unknown>).message === "Not linked to any classes"
    );
  } catch (e) {
    return false;
  }
}

const notLinkedToAnyClassesErrorMessage = "Not linked to any classes";

function isNotLinkedToAnyClassesError(error: unknown) {
  return (
    typeof error === "object" &&
    !!error &&
    error.constructor === Error &&
    (error as Error).message === notLinkedToAnyClassesErrorMessage
  );
}

async function loginGoogle(
  request: RequestSender,
  code: string,
  redirectUri: string,
): Promise<ExternalLoginResponse> {
  try {
    const response = await request<any>("post", "/auth/login/google", {
      code,
      redirectUri,
    });
    return {
      id: response.data.data.id,
      username: response.data.data.sUserName,
      userType: response.data.data.eUserType,
      loginType: {
        type: response.data.data.oLoginType.eType,
      },
      authToken: response.headers.authorization,
    };
  } catch (e) {
    if (
      axios.isAxiosError(e) &&
      e.response &&
      isNotLinkedToAnyClassesResponse(e.response)
    ) {
      throw new Error(notLinkedToAnyClassesErrorMessage);
    }

    const message = getStandardServerError(e);
    if (message) {
      throw new Error(message);
    }
    throw e;
  }
}

export { isNotLinkedToAnyClassesError };
export default loginGoogle;
