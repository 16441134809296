/* eslint-disable no-underscore-dangle */
import { parseSuccessfulResponse, RequestSender } from "./helpers";
import parseStudentDetail from "./parseStudentDetail";

async function googleCourseStudents(request: RequestSender, id: string) {
  const response = await request("get", `/google-courses/${id}/students`);
  return parseSuccessfulResponse(response, (data) => {
    if (!Array.isArray(data)) {
      throw new Error("Not array");
    }
    return data.map(parseStudentDetail);
  });
}

export default googleCourseStudents;
