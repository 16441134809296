/* eslint-disable no-underscore-dangle */
import { parseSuccessfulResponse, RequestSender } from "./helpers";
import { Profile } from "./model";

async function getProfile(request: RequestSender): Promise<Profile> {
  const response = await request("get", `/profile/`);
  return parseSuccessfulResponse(response, (data) => {
    return {
      id: data.id,
      type: data.eUserType,
      loginType: { type: data.oLoginType.eType },
      firstName: data.sFirstName,
      gender: data.eGender,
      monthOfBirth: data.sMonthOfBirth,
      yearOfBirth: data.sYearOfBirth,
      viewedPromotions: data.aViewedPromotions ?? [],
      // Need to convert empty string to undefined
      username: data.sUserName ? data.sUserName : undefined,
    };
  });
}

export default getProfile;
