import { RequestSender } from "./helpers";

async function deleteStudent(
  request: RequestSender,
  id: string,
): Promise<void> {
  await request("post", `/student/delete/${id}`);
}

export default deleteStudent;
