import React, {
  useCallback,
  createContext,
  ReactNode,
  useContext,
  useMemo,
} from "react";
import useCookie from "react-use/lib/useCookie";
import { isValidUser, User } from "~/model";

type SignedInAuthState = {
  readonly type: "signedIn";
  readonly user: User;
  signIn(user: User): void;
  signOut(): void;
};

type SignedOutAuthState = {
  readonly type: "signedOut";
  signIn(user: User): void;
};

type AuthState = SignedInAuthState | SignedOutAuthState;

const Context = createContext<AuthState | undefined>(undefined);

const CleverContext = createContext<string | undefined>(undefined);
const GoogleContext = createContext<string | undefined>(undefined);

type AuthProviderProps = {
  readonly cleverClientId: string;
  readonly googleClientId: string;
  readonly children: ReactNode;
};

function AuthProvider({
  cleverClientId,
  googleClientId,
  children,
}: AuthProviderProps) {
  const [serialisedUser, setSerialisedUser, removeUser] = useCookie("authUser");
  const setUser = useCallback(
    (user: User | undefined) => {
      if (user) {
        setSerialisedUser(JSON.stringify(user), {
          expires: 31,
          secure: window.location.protocol === "https:",
          sameSite: "strict",
        });
        return;
      }

      removeUser();
    },
    [removeUser, setSerialisedUser],
  );
  const user = useMemo(() => {
    if (!serialisedUser) {
      return undefined;
    }

    let raw;
    try {
      raw = JSON.parse(serialisedUser);
      if (!isValidUser(raw)) {
        return undefined;
      }
      return raw;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }, [serialisedUser]);

  const authState = useMemo((): AuthState => {
    if (user) {
      return {
        type: "signedIn",
        user,
        signIn: setUser,
        signOut: removeUser,
      };
    }
    return {
      type: "signedOut",
      signIn: setUser,
    };
  }, [user, removeUser, setUser]);

  return (
    <CleverContext.Provider value={cleverClientId}>
      <GoogleContext.Provider value={googleClientId}>
        <Context.Provider value={authState}>{children}</Context.Provider>
      </GoogleContext.Provider>
    </CleverContext.Provider>
  );
}

function useCleverClientId() {
  const state = useContext(CleverContext);
  if (state === undefined) {
    throw new Error("No auth context");
  }
  return state;
}

function useGoogleSsoClientId() {
  const state = useContext(GoogleContext);
  if (state === undefined) {
    throw new Error("No auth context");
  }
  return state;
}

function useAuthState() {
  const state = useContext(Context);
  if (state === undefined) {
    throw new Error("No auth context");
  }
  return state;
}

function useSignedInAuthState() {
  const state = useAuthState();
  if (state.type !== "signedIn") {
    throw new Error("Not signed in");
  }
  return state;
}

function useSignedOutAuthState() {
  const state = useAuthState();
  if (state.type !== "signedOut") {
    throw new Error("Not signed out");
  }
  return state;
}

function isSignedInAuthState(state: AuthState): state is SignedInAuthState {
  return state.type === "signedIn";
}

export {
  useCleverClientId,
  useGoogleSsoClientId,
  useAuthState,
  useSignedOutAuthState,
  useSignedInAuthState,
  AuthProvider,
  isSignedInAuthState,
};
