import { parseISO } from "date-fns/parseISO";
import { Student } from "./model";
import { BackendStudent } from "./backendModel";

function parseStudent(raw: BackendStudent): Student {
  const base = {
    id: raw._id,
    gender: raw.eGender,
    class: raw.sClass,
    firstName: raw.sFirstName,
    grade: raw.sGrade,
    mobile: raw.sMobile,
    monthOfBirth: raw.sMonthOfBirth,
    // Need empty string to become undefined
    username: raw.sUserName ? raw.sUserName : undefined,
    yearOfBirth: raw.sYearOfBirth ? parseInt(raw.sYearOfBirth, 10) : undefined,
  };

  if (raw.bIsSubscribed) {
    return {
      isSubscribed: true,
      subscribedUntil: parseISO(raw.dSubscribedUntil!),
      ...base,
    };
  }

  return {
    isSubscribed: false,
    ...base,
  };
}

export default parseStudent;
