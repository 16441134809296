import { isAxiosErrorWithMessage, RequestSender } from "./helpers";

async function forgotPassword(
  request: RequestSender,
  emailAddress: string,
): Promise<string> {
  try {
    const response = await request<any>("post", "/auth/password/forgot", {
      sEmail: emailAddress,
    });
    return response.headers.sverificationtoken;
  } catch (e) {
    if (isAxiosErrorWithMessage(e, 404, "User not found")) {
      throw new Error("User not found");
    }

    throw e;
  }
}

export default forgotPassword;
