/* eslint-disable no-underscore-dangle */
import { parseSuccessfulResponse, RequestSender } from "./helpers";
import { CleverSection } from "./model";
import parseCleverSection from "./parseCleverSection";

async function cleverSections(
  request: RequestSender,
): Promise<ReadonlyArray<CleverSection>> {
  const response = await request("get", "/clever-sections");
  return parseSuccessfulResponse(response, (data) => {
    if (!Array.isArray(data)) {
      throw new Error("Not array");
    }
    return data.map(parseCleverSection);
  });
}

export default cleverSections;
