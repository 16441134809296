import { RequestSender } from "./helpers";

type PaymentCheckoutSessionInput = {
  readonly items: ReadonlyArray<{
    readonly studentIds: ReadonlyArray<string>;
    readonly priceLookupKey: string;
  }>;
  readonly successUrl: string;
  readonly cancelUrl: string;
};

async function createPaymentCheckoutSession(
  request: RequestSender,
  input: PaymentCheckoutSessionInput,
): Promise<string> {
  const response = await request<{ redirectUrl: string }>(
    "post",
    `/profile/payment-checkout-sessions`,
    input,
  );
  if (response.data.message !== "success") {
    throw new Error(response.data.message);
  }
  if (!response.data.data) {
    throw new Error("Invalid response");
  }
  return response.data.data.redirectUrl;
}

export type { PaymentCheckoutSessionInput };
export default createPaymentCheckoutSession;
