import { RequestSender } from "./helpers";

async function changePassword(
  request: RequestSender,
  oldPassword: string,
  newPassword: string,
): Promise<void> {
  await request("put", "/profile/password/", {
    sPassword: oldPassword,
    sNewPassword: newPassword,
  });
}

export default changePassword;
