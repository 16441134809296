import axios from "axios";
import { isStandardServerPayload, RequestSender } from "./helpers";

async function checkUserName(
  request: RequestSender,
  username: string,
): Promise<true | string> {
  try {
    const response = await request("post", `/student/validate/${username}`);
    if (response.data.message === "success") {
      return true;
    }
    return response.data.message;
  } catch (e) {
    if (
      axios.isAxiosError(e) &&
      e.response &&
      isStandardServerPayload(e.response.data)
    ) {
      return e.response.data.message;
    }
    throw e;
  }
}

export default checkUserName;
