/* eslint-disable no-underscore-dangle */
import { parseSuccessfulResponse, RequestSender } from "./helpers";
import parseStudentDetail from "./parseStudentDetail";

async function getStudent(request: RequestSender, id: string) {
  const response = await request("post", `/student/get/${id}`);
  return parseSuccessfulResponse(response, parseStudentDetail);
}

export default getStudent;
