import { RequestSender } from "./helpers";

async function setCleverSectionEnabled(
  request: RequestSender,
  id: string,
  enabled: boolean,
): Promise<void> {
  const response = await request(
    "post",
    `/clever-sections/${id}/enabled`,
    enabled,
  );
  if (response.data.message !== "success") {
    throw new Error(response.data.message);
  }
}

export default setCleverSectionEnabled;
