import React, { ReactNode } from "react";
import { AuthProvider } from "~/hooks/authContext";
import { ApiProvider } from "~/hooks/apiContext";
import { StripeProvider } from "~/hooks/stripeContext";

type AppProviderProps = {
  readonly apiBaseUrl: string;
  readonly stripeKey: string;
  readonly cleverClientId: string;
  readonly googleClientId: string;
  readonly children: ReactNode;
};

function AppProvider({
  apiBaseUrl,
  googleClientId,
  cleverClientId,
  stripeKey,
  children,
}: AppProviderProps) {
  return (
    <StripeProvider stripeKey={stripeKey}>
      <AuthProvider
        cleverClientId={cleverClientId}
        googleClientId={googleClientId}
      >
        <ApiProvider baseUrl={apiBaseUrl}>{children}</ApiProvider>
      </AuthProvider>
    </StripeProvider>
  );
}

export { AppProvider };
