import React, { createContext, ReactNode, useContext, useMemo } from "react";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  UseMutationOptions,
} from "@tanstack/react-query";
import { ApiClient, createApi } from "~/backendApi/index";
import { useAuthState } from "./authContext";

const Context = createContext<ApiClient | undefined>(undefined);

type ApiProviderProps = {
  readonly baseUrl: string;
  readonly children: ReactNode;
};

function ApiProvider({ baseUrl, children }: ApiProviderProps) {
  const authState = useAuthState();
  const api = useMemo(() => {
    if (authState.type === "signedIn") {
      return createApi(baseUrl, authState.signOut, authState.user.authToken);
    }
    return createApi(baseUrl, () => {});
  }, [baseUrl, authState]);
  const queryClient = useMemo(() => new QueryClient(), []);

  return (
    <Context.Provider value={api}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </Context.Provider>
  );
}

function useApiClient() {
  const client = useContext(Context);
  if (client === undefined) {
    throw new Error("No api context");
  }
  return client;
}

function useApiMutation<TVariables, TData = unknown, TContext = unknown>(
  call: (client: ApiClient, input: TVariables) => Promise<TData>,
  options?: Omit<
    UseMutationOptions<TData, unknown, TVariables, TContext>,
    "mutationFn"
  >,
) {
  const apiClient = useApiClient();
  return useMutation((input: TVariables) => {
    return call(apiClient, input);
  }, options);
}

export { useApiClient, useApiMutation, ApiProvider };
