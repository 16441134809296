import { RequestSender } from "./helpers";

async function changeStudentPassword(
  request: RequestSender,
  studentId: string,
  newPassword: string,
): Promise<void> {
  await request("put", `/student/change-password/${studentId}`, {
    newPassword,
  });
}

export default changeStudentPassword;
