import { BackendStudentDetail } from "./backendModel";
import { StudentDetail } from "~/backendApi/model";
import parseStudent from "./parseStudent";

function parseStudentDetail(data: BackendStudentDetail): StudentDetail {
  const baseStudent = parseStudent(data);
  return {
    ...baseStudent,
    timeSpent: data.TimeSpent,
    currentChapter: data.CurrentChapter
      ? parseInt(data.CurrentChapter, 10)
      : undefined,
    currentChapterProgress: data.CurrentChapterProgress
      ? parseFloat(data.CurrentChapterProgress)
      : undefined,
    levelNumber: data.LevelNumber ? parseInt(data.LevelNumber, 10) : undefined,
    pronounGender: data.PronounGender,
    totalChaptersCompleted: data.TotalChaptersCompleted
      ? parseInt(data.TotalChaptersCompleted, 10)
      : undefined,
    totalChaptersOfTheGame: data.TotalChaptersOfTheGame
      ? parseInt(data.TotalChaptersOfTheGame, 10)
      : undefined,
    xpPoints: data.XPPoints ? parseInt(data.XPPoints, 10) : undefined,
    completedReviewQuestions: data.CompletedReviewQuestions,
    totalReviewQuestions: data.TotalReviewQuestions,
  };
}

export default parseStudentDetail;
