import { RequestSender } from "./helpers";

async function createPromotionsViews(
  request: RequestSender,
  promotions: readonly string[],
): Promise<void> {
  const response = await request("post", `/profile/promotions`, {
    promotions,
  });
  if (response.data.message !== "success") {
    throw new Error(response.data.message);
  }
}

export default createPromotionsViews;
