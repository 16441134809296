import { RequestSender } from "./helpers";

type ContactDetails = {
  readonly firstName: string;
  readonly lastName: string;
  readonly emailAddress: string;
  readonly source?: string;
  readonly subject: string;
  readonly message: string;
};

async function createContact(request: RequestSender, input: ContactDetails) {
  const response = await request("post", "/contacts", input);
  if (response.data.message !== "created") {
    throw new Error(response.data.message);
  }
}

export type { ContactDetails };
export default createContact;
