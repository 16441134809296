import React, { createContext, ReactNode, useContext } from "react";

const Context = createContext<string | undefined>(undefined);

type StripeProviderProps = {
  readonly stripeKey: string;
  readonly children: ReactNode;
};

function StripeProvider({ stripeKey, children }: StripeProviderProps) {
  return <Context.Provider value={stripeKey}>{children}</Context.Provider>;
}

function useStripeKey() {
  const client = useContext(Context);
  if (client === undefined) {
    throw new Error("No stripe context");
  }
  return client;
}

export { StripeProvider, useStripeKey };
