/* eslint-disable no-underscore-dangle */
import { parseSuccessfulResponse, RequestSender } from "./helpers";
import parseStudentDetail from "./parseStudentDetail";

async function students(request: RequestSender) {
  const response = await request("post", "/student/list");
  return parseSuccessfulResponse(response, (data) => {
    if (!Array.isArray(data)) {
      throw new Error("Not array");
    }
    return data.map(parseStudentDetail);
  });
}

export default students;
