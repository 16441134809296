import { Student } from "./model";

type StudentInput = Pick<
  Student,
  "firstName" | "monthOfBirth" | "username" | "yearOfBirth"
> & {
  readonly password: string;
};

function studentInputToApiData(input: StudentInput): Record<string, unknown> {
  return {
    sFirstName: input.firstName,
    sMonthOfBirth: input.monthOfBirth,
    sPassword: input.password,
    sUserName: input.username,
    sYearOfBirth: input.yearOfBirth,
  };
}

export { studentInputToApiData };
export type { StudentInput };
