import { RequestSender } from "./helpers";

async function resendOtpLink(
  request: RequestSender,
  emailAddress: string,
): Promise<string> {
  const response = await request<any>("post", "/auth/otp/send-link", {
    sEmail: emailAddress,
  });
  return response.headers.sverificationtoken;
}

export default resendOtpLink;
