import { parseSuccessfulResponse, RequestSender } from "./helpers";

type SubscriptionPrice = {
  readonly lookupKey: string;
  readonly price: number;
  readonly interval: "year" | "month";
  readonly intervalCount: number;
};

async function subscriptionPrices(
  request: RequestSender,
): Promise<ReadonlyArray<SubscriptionPrice>> {
  const response = await request("get", `/payment/subscription-prices`);
  return parseSuccessfulResponse(response, (d) => d);
}

export type { SubscriptionPrice };
export default subscriptionPrices;
