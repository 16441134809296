import { RequestSender } from "./helpers";

async function createFreeTrialForStudent(request: RequestSender, id: string) {
  const response = await request("post", `/student/trial-subscription/${id}`);
  if (response.status !== 204) {
    throw new Error(response.data.message);
  }
}

export default createFreeTrialForStudent;
