import React, { useState, useEffect, ReactNode } from "react";
import { Link } from "gatsby";
import useEffectOnce from "react-use/lib/useEffectOnce";
import uiIconsImage from "~/images/ui-icons.svg";
import { useAuthState } from "~/hooks/authContext";
import logoImage from "~/images/roundedlogo-01.png";
import facebookImage from "~/images/facebook-icon.png";
import instagramImage from "~/images/instagram-icon.png";
import pinterestImage from "~/images/pinterest-icon.png";
import twitterImage from "~/images/twitter-icon.png";
import { useApiClient } from "~/hooks/apiContext";
import Button from "~/components/Button";
import "./ss-layout.css";
import "./ss-inline.css";

type LayoutProps = {
  readonly children: ReactNode | ReadonlyArray<ReactNode>;
};

type LinkNavItem = {
  readonly to: string;
  readonly label: string;
};

type GroupNavItem = {
  readonly id: string;
  readonly label: string;
  readonly children: ReadonlyArray<LinkNavItem>;
};

const navItems: ReadonlyArray<GroupNavItem | LinkNavItem> = [
  // Viju requested to be removed for now
  // {
  //   id: "games",
  //   label: "Games",
  //   children: [
  //     { label: "ProblemScape", to: "/stepbystep/" },
  //     { label: "Sama", to: "/sama/" },
  //   ],
  // },
  {
    to: "/research/",
    label: "Research",
  },
  {
    to: "/support-center/",
    label: "Support",
  },
];

function Layout({ children }: LayoutProps) {
  useEffectOnce(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "/index_files/site-bundle.js";
    script.onload = () => {
      document.dispatchEvent(new Event("DOMContentLoaded"));
    };
    document.body.appendChild(script);
  });

  const [isMobileActive, setMobileActive] = useState(false);
  useEffect(() => {
    document.body.classList.toggle("is-mobile-overlay-active", isMobileActive);
  }, [isMobileActive]);

  // Profile
  const authState = useAuthState();
  const apiClient = useApiClient();

  // Change pass
  const onSignOut = async () => {
    if (authState.type !== "signedIn") {
      throw new Error("Invalid state");
    }
    await apiClient.logout();
    authState.signOut();
    // Doesn't work. PrivateRoutes get there first (I think)
    // navigate("/");
    window.location.assign("/");
  };

  return (
    <>
      <div className="Loader" />
      <div
        className="Mobile loaded"
        data-nc-base="mobile-bar"
        data-controller="AncillaryLayout"
        data-controllers-bound="AncillaryLayout"
      >
        <div
          className="Mobile-bar Mobile-bar--top"
          data-nc-group="top"
          data-controller="MobileOffset"
          data-controllers-bound="MobileOffset"
        >
          <div data-nc-container="top-left">
            <Link
              to="/"
              className="Mobile-bar-branding"
              data-nc-element="branding"
              data-content-field="site-title"
            >
              <img
                src={logoImage}
                alt="RoundEd Learning Games"
                className="Mobile-bar-branding-logo"
              />
            </Link>
          </div>
          <div data-nc-container="top-center" />
          <div data-nc-container="top-right">
            <button
              type="button"
              onClick={() => setMobileActive(true)}
              className="Mobile-bar-menu"
              data-nc-element="menu-icon"
              data-controller-overlay="menu"
              data-controller="MobileOverlayToggle"
              data-controllers-bound="MobileOverlayToggle"
            >
              <svg className="Icon Icon--hamburger" viewBox="0 0 24 18">
                <use
                  href={`${uiIconsImage}#hamburger-icon--even`}
                  className="use--even"
                />
                <use
                  href={`${uiIconsImage}#hamburger-icon--odd`}
                  className="use--odd"
                />
              </svg>
              <svg className="Icon Icon--hotdog" viewBox="0 0 24 14">
                <use
                  href={`${uiIconsImage}#hotdog-icon--even`}
                  className="use--even"
                />
                <use
                  href={`${uiIconsImage}#hotdog-icon--odd`}
                  className="use--odd"
                />
              </svg>
              <svg className="Icon Icon--plus" viewBox="0 0 20 20">
                <use
                  href={`${uiIconsImage}#plus-icon--even`}
                  className="use--even"
                />
                <use
                  href={`${uiIconsImage}#plus-icon--odd`}
                  className="use--odd"
                />
              </svg>
              <svg className="Icon Icon--dots-horizontal" viewBox="0 0 25 7">
                <use
                  href={`${uiIconsImage}#dots-horizontal-icon--even`}
                  className="use--even"
                />
                <use
                  href={`${uiIconsImage}#dots-horizontal-icon--odd`}
                  className="use--odd"
                />
              </svg>
              <svg className="Icon Icon--dots-vertical" viewBox="0 0 7 25">
                <use
                  href={`${uiIconsImage}#dots-vertical-icon--even`}
                  className="use--even"
                />
                <use
                  href={`${uiIconsImage}#dots-vertical-icon--odd`}
                  className="use--odd"
                />
              </svg>
              <svg className="Icon Icon--squares-horizontal" viewBox="0 0 25 7">
                <use
                  href={`${uiIconsImage}#squares-horizontal-icon--even`}
                  className="use--even"
                />
                <use
                  href={`${uiIconsImage}#squares-horizontal-icon--odd`}
                  className="use--odd"
                />
              </svg>
              <svg className="Icon Icon--squares-vertical" viewBox="0 0 7 25">
                <use
                  href={`${uiIconsImage}#squares-vertical-icon--even`}
                  className="use--even"
                />
                <use
                  href={`${uiIconsImage}#squares-vertical-icon--odd`}
                  className="use--odd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          className="Mobile-bar Mobile-bar--bottom"
          data-nc-group="bottom"
          data-controller="MobileOffset"
          data-controllers-bound="MobileOffset"
        >
          <div data-nc-container="bottom-left" />
          <div data-nc-container="bottom-center" />
          <div data-nc-container="bottom-right" />
        </div>
        <div className="Mobile-overlay">
          <div
            className="Mobile-overlay-menu"
            data-controller="MobileOverlayFolders"
            data-controllers-bound="MobileOverlayFolders"
          >
            <div className="Mobile-overlay-menu-main">
              <nav
                className="Mobile-overlay-nav Mobile-overlay-nav--primary"
                data-content-field="navigation"
              >
                {navItems.map((navItem) => {
                  if ("children" in navItem) {
                    return (
                      <button
                        key={navItem.label}
                        type="button"
                        className="Mobile-overlay-nav-item Mobile-overlay-nav-item--folder"
                        data-controller-folder-toggle={navItem.id}
                      >
                        <span className="Mobile-overlay-nav-item--folder-label">
                          {navItem.label}
                        </span>
                      </button>
                    );
                  }
                  return (
                    <Link
                      key={navItem.label}
                      to={navItem.to}
                      className="Mobile-overlay-nav-item"
                    >
                      {navItem.label}
                    </Link>
                  );
                })}
              </nav>
              <nav
                className="Mobile-overlay-nav Mobile-overlay-nav--secondary"
                data-content-field="navigation"
              >
                {authState.type === "signedOut" && (
                  <>
                    <Link to="/dashboard/" className="Mobile-overlay-nav-item">
                      Login
                    </Link>
                  </>
                )}
                {authState.type === "signedIn" && (
                  <>
                    <button
                      type="button"
                      className="Mobile-overlay-nav-item"
                      onClick={onSignOut}
                      title="Sign out"
                    >
                      Sign out
                    </button>
                  </>
                )}
              </nav>
            </div>
            <div
              className="Mobile-overlay-folders"
              data-content-field="navigation"
            >
              {navItems
                .filter((n): n is GroupNavItem => "id" in n)
                .map((navItem) => (
                  <div
                    key={navItem.id}
                    className="Mobile-overlay-folder"
                    data-controller-folder={navItem.id}
                  >
                    <button
                      className="Mobile-overlay-folder-item Mobile-overlay-folder-item--toggle"
                      data-controller-folder-toggle={navItem.id}
                    >
                      <span className="Mobile-overlay-folder-item--toggle-label">
                        Back
                      </span>
                    </button>
                    {navItem.children.map((child) => (
                      <Link
                        key={child.label}
                        to={child.to}
                        className="Mobile-overlay-folder-item"
                      >
                        {child.label}
                      </Link>
                    ))}
                  </div>
                ))}
            </div>
          </div>
          <button
            type="button"
            onClick={() => setMobileActive(false)}
            className="Mobile-overlay-close"
            data-controller="MobileOverlayToggle"
            data-controllers-bound="MobileOverlayToggle"
          >
            <svg className="Icon Icon--close" viewBox="0 0 16 16">
              <use href={`${uiIconsImage}#close-icon`} />
            </svg>
          </button>
          <div
            className="Mobile-overlay-back"
            data-controller="MobileOverlayToggle"
            data-controllers-bound="MobileOverlayToggle"
          />
        </div>
      </div>
      <div
        className="Site loaded"
        data-nc-base="header"
        data-controller="AncillaryLayout"
        id="yui_3_17_2_1_1651536660296_83"
        data-controllers-bound="AncillaryLayout"
        style={{ background: "white" }}
      >
        <div className="sqs-announcement-bar-dropzone" />
        <header className="Header Header--top">
          <div className="Header-inner Header-inner--top" data-nc-group="top">
            <div data-nc-container="top-left">
              <Link
                to="/"
                className="Header-branding"
                data-nc-element="branding"
                data-content-field="site-title"
              >
                <img
                  src={logoImage}
                  alt="RoundEd Learning Games"
                  className="Header-branding-logo"
                />
              </Link>
            </div>
            <div data-nc-container="top-center" />
            <div data-nc-container="top-right">
              <nav
                className="Header-nav Header-nav--primary"
                data-nc-element="primary-nav"
                data-content-field="navigation"
              >
                <div className="Header-nav-inner">
                  {authState.type === "signedIn" && (
                    <Link to="/dashboard/" className="Header-nav-item">
                      Dashboard
                    </Link>
                  )}
                  {navItems.map((navItem) => {
                    if (!("children" in navItem)) {
                      return (
                        <Link
                          key={navItem.label}
                          to={navItem.to}
                          className="Header-nav-item"
                        >
                          {navItem.label}
                        </Link>
                      );
                    }
                    return (
                      <span
                        key={navItem.label}
                        className="Header-nav-item Header-nav-item--folder"
                      >
                        <span
                          className="Header-nav-folder-title"
                          data-controller="HeaderNavFolderTouch"
                        >
                          {navItem.label}
                        </span>
                        <span className="Header-nav-folder">
                          {navItem.children.map((child) => (
                            <Link
                              key={child.label}
                              to={child.to}
                              className="Header-nav-folder-item"
                              data-test="template-nav"
                            >
                              {child.label}
                            </Link>
                          ))}
                        </span>
                      </span>
                    );
                  })}
                </div>
              </nav>
              <nav
                className="Header-nav Header-nav--secondary"
                data-nc-element="secondary-nav"
                data-content-field="navigation"
              >
                <div className="Header-nav-inner">
                  {authState.type === "signedOut" && (
                    <>
                      <Link to="/dashboard/" className="Header-nav-item">
                        Login
                      </Link>
                      <Link to="/stepbystep/" className="Header-nav-item">
                        Explore
                      </Link>
                    </>
                  )}
                  {authState.type === "signedIn" && (
                    <div className="menu-dropdown">
                      <Button
                        type="button"
                        colour="red"
                        size="small"
                        variant="outline"
                        onClick={onSignOut}
                      >
                        Sign out
                      </Button>
                    </div>
                  )}
                </div>
              </nav>
            </div>
          </div>
        </header>
        {children}
        <footer
          className="Footer"
          role="contentinfo"
          data-controller="FooterBreakpoints"
          id="yui_3_17_2_1_1651536660296_409"
          data-controllers-bound="FooterBreakpoints"
        >
          <div
            className="Footer-inner clear"
            id="yui_3_17_2_1_1651536660296_408"
          >
            <div
              className="sqs-layout sqs-grid-12 columns-12 Footer-blocks Footer-blocks--top sqs-alternate-block-style-container"
              data-layout-label="Footer Top Blocks"
              data-type="block-field"
              id="footerBlocksTop"
            >
              <div className="row sqs-row" id="yui_3_17_2_1_1651536660296_407">
                <div className="col sqs-col-1 span-1">
                  <div
                    className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
                    data-block-type="21"
                    id="block-yui_3_17_2_1_1585113778955_8774"
                  >
                    <div className="sqs-block-content">&nbsp;</div>
                  </div>
                </div>
                <div className="col sqs-col-10 span-10">
                  <div className="row sqs-row">
                    <div className="col sqs-col-12">
                      <div className="sqs-block gallery-block sqs-block-gallery">
                        <div className="sqs-block-content sqs-intrinsic">
                          <div
                            className="block-animation-none"
                            style={{
                              height: "100%",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-evenly",
                              flexWrap: "wrap",
                              gap: 16,
                            }}
                          >
                            <img
                              src="/index_files/NSFlogo.png"
                              height={140}
                              alt="NSFlogo"
                            />
                            <img
                              src="/index_files/Seal-Best-Homeschooling-Programs-&amp;-Resources-2021-2.png"
                              height={140}
                              alt="Seal - Best Homeschooling Programs &amp; Resources 2021 2.png"
                            />
                            <img
                              src="/index_files/content/v1/5ac8329b2487fdb8c2b0f50f/1619736938388-P813W5L41CZS8A0WA9U7/awardsbanner-03.png"
                              height={140}
                              alt="awardsbanner-03.png"
                            />
                            <img
                              src="/index_files/content/v1/5ac8329b2487fdb8c2b0f50f/1619736938373-QGEGH5P8INBUA3HWASXG/awardsbanner-02.png"
                              height={100}
                              alt="awardsbanner-02.png"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="sqs-block horizontalrule-block sqs-block-horizontalrule"
                    data-block-type="47"
                    id="block-yui_3_17_2_1_1592239416537_49398"
                  >
                    <div className="sqs-block-content">
                      <hr />
                    </div>
                  </div>
                  <div
                    className="row sqs-row"
                    id="yui_3_17_2_1_1651536660296_449"
                  >
                    <div
                      className="col sqs-col-2 span-2"
                      id="yui_3_17_2_1_1651536660296_448"
                    >
                      <div
                        className="sqs-block image-block sqs-block-image sqs-text-ready"
                        data-block-type="5"
                        id="block-yui_3_17_2_1_1592239416537_40674"
                      >
                        <div
                          className="sqs-block-content"
                          id="yui_3_17_2_1_1651536660296_447"
                        >
                          <div
                            className="image-block-outer-wrapper
                                layout-caption-below
                                design-layout-inline
                                combination-animation-none
                                individual-animation-none
                                individual-text-animation-none
                                sqs-narrow-width"
                            data-test="image-block-inline-outer-wrapper"
                            id="yui_3_17_2_1_1651536660296_446"
                          >
                            <figure
                              className="
                                sqs-block-image-figure
                                intrinsic
                                "
                              style={{ maxWidth: "400px" }}
                              id="yui_3_17_2_1_1651536660296_445"
                            >
                              <div
                                className="image-block-wrapper"
                                data-animation-role="image"
                                id="yui_3_17_2_1_1651536660296_444"
                              >
                                <div
                                  className="sqs-image-shape-container-element
                                    has-aspect-ratio
                                    "
                                  style={{
                                    position: "relative",
                                    paddingBottom: "100%",
                                    overflow: "hidden",
                                  }}
                                  id="yui_3_17_2_1_1651536660296_443"
                                >
                                  <noscript>
                                    <img
                                      src="/index_files/content/v1/5ac8329b2487fdb8c2b0f50f/1592240100934-B4CO6TFRFCBBYCRGMTPC/digitalpromisecertification.png"
                                      alt="digitalpromisecertification.png"
                                    />
                                  </noscript>
                                  <img
                                    className="thumb-image loaded"
                                    data-src="/index_files/content/v1/5ac8329b2487fdb8c2b0f50f/1592240100934-B4CO6TFRFCBBYCRGMTPC/digitalpromisecertification.png"
                                    data-image="/index_files/content/v1/5ac8329b2487fdb8c2b0f50f/1592240100934-B4CO6TFRFCBBYCRGMTPC/digitalpromisecertification.png"
                                    data-image-dimensions="400x400"
                                    data-image-focal-point="0.5,0.5"
                                    data-load="false"
                                    data-image-id="5ee7a7e4a1aef52e9a8b2ace"
                                    data-type="image"
                                    style={{
                                      left: "0%",
                                      top: "0%",
                                      width: "100%",
                                      height: "100%",
                                      position: "absolute",
                                    }}
                                    alt="digitalpromisecertification.png"
                                    data-image-resolution="300w"
                                    src="/index_files/digitalpromisecertification.png"
                                  />
                                </div>
                              </div>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col sqs-col-8">
                      <div
                        className="sqs-block html-block sqs-block-html"
                        data-block-type="2"
                        id="block-yui_3_17_2_1_1592239416537_46418"
                      >
                        <div className="sqs-block-content">
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            Digital Promise Research-Based Design Product
                            Certification: &quot;Research about how people learn
                            is core to the theoretical framework that drives
                            product design and evidence throughout the product.
                            The product team shares the research behind the
                            design publicly.&quot;
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row sqs-row">
                    <div className="col sqs-col-1">&nbsp;</div>
                    <div className="col sqs-col-2 span-2">
                      <div
                        className="sqs-block html-block sqs-block-html"
                        data-block-type="2"
                        id="block-yui_3_17_2_1_1585115564195_6037"
                      >
                        <div className="sqs-block-content">
                          <h3
                            style={{
                              textAlign: "center",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            <Link to="/terms-of-use/">Terms of Use</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col sqs-col-2 span-2">
                      <div
                        className="sqs-block html-block sqs-block-html"
                        data-block-type="2"
                        id="block-yui_3_17_2_1_1585115564195_6624"
                      >
                        <div className="sqs-block-content">
                          <h3
                            style={{
                              textAlign: "center",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            <Link to="/privacy-policy/">Privacy Policy</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col sqs-col-2 span-2">
                      <div
                        className="sqs-block html-block sqs-block-html"
                        data-block-type="2"
                        id="block-yui_3_17_2_1_1585340836863_8897"
                      >
                        <div className="sqs-block-content">
                          <h3
                            style={{
                              textAlign: "center",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            <Link to="/research/">RESEARCH</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col sqs-col-2 span-2">
                      <div
                        className="sqs-block html-block sqs-block-html"
                        data-block-type="2"
                        id="block-yui_3_17_2_1_1585341456742_10667"
                      >
                        <div className="sqs-block-content">
                          <h3
                            style={{
                              textAlign: "center",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            <Link to="/about/">ABOUT US</Link>
                          </h3>
                        </div>
                      </div>
                    </div> */}
                    <div className="col sqs-col-2 span-2">
                      <div
                        className="sqs-block html-block sqs-block-html"
                        data-block-type="2"
                        id="block-yui_3_17_2_1_1585340836863_6940"
                      >
                        <div className="sqs-block-content">
                          <h3
                            style={{
                              textAlign: "center",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            <Link to="/contact/">Contact</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col sqs-col-1">&nbsp;</div>
                  </div>
                  <div className="row sqs-row">
                    <div className="col sqs-col-12">
                      <div
                        className="sqs-block socialaccountlinks-v2-block sqs-block-socialaccountlinks-v2"
                        data-block-type="54"
                        id="block-yui_3_17_2_1_1656432285895_18886"
                      >
                        <div className="sqs-block-content">
                          <div className="sqs-svg-icon--outer social-icon-alignment-center social-icons-color-black social-icons-size-small social-icons-style-regular ">
                            {/* <style>
    #block-yui_3_17_2_1_1656432285895_18886 .social-icons-style-border .sqs-svg-icon--wrapper {
      
        box-shadow: 0 0 0 2px inset;
      
      border: none; 
    }
  </style> */}
                            <nav className="sqs-svg-icon--list">
                              <a
                                href="http://www.instagram.com/roundedlearning"
                                target="_blank"
                                className="sqs-svg-icon--wrapper instagram-unauth"
                                aria-label="Instagram"
                                rel="noreferrer"
                              >
                                <img
                                  src={instagramImage}
                                  className="sqs-svg-icon--social"
                                  alt="Instagram"
                                />
                              </a>
                              <a
                                href="http://twitter.com/roundedlearning"
                                target="_blank"
                                className="sqs-svg-icon--wrapper twitter-unauth"
                                aria-label="Twitter"
                                rel="noreferrer"
                              >
                                <img
                                  src={twitterImage}
                                  className="sqs-svg-icon--social"
                                  alt="Twitter"
                                />
                              </a>
                              <a
                                href="http://facebook.com/roundedlearning"
                                target="_blank"
                                className="sqs-svg-icon--wrapper facebook-unauth"
                                aria-label="Facebook"
                                rel="noreferrer"
                              >
                                <img
                                  src={facebookImage}
                                  className="sqs-svg-icon--social"
                                  alt="Facebook"
                                />
                              </a>
                              <a
                                href="https://www.pinterest.com/roundedlearninginc"
                                target="_blank"
                                className="sqs-svg-icon--wrapper pinterest-unauth"
                                aria-label="Pinterest"
                                rel="noreferrer"
                              >
                                <img
                                  src={pinterestImage}
                                  className="sqs-svg-icon--social"
                                  alt="Pinterest"
                                />
                              </a>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col sqs-col-1 span-1">
                  <div
                    className="sqs-block spacer-block sqs-block-spacer sized vsize-1"
                    data-block-type="21"
                    id="block-yui_3_17_2_1_1585113778955_8774"
                  >
                    <div className="sqs-block-content">&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Footer-middle">
              <div className="Footer-business">
                <div className="Footer-business-info">
                  <div className="Footer-business-info-item Footer-business-info-item--name">
                    www.roundedlearning.com
                  </div>
                  <div className="Footer-business-info-item">
                    218 Del Valle Court,
                  </div>
                  <div className="Footer-business-info-item">
                    Pleasanton, CA, 94566,
                  </div>
                  <div className="Footer-business-info-item Footer-business-info-item--country">
                    United States
                  </div>
                  <a
                    href="tel:(408) 887 1334"
                    rel="tel"
                    className="Footer-business-info-item Footer-business-info-item--phone"
                  >
                    (408) 887 1334
                  </a>
                  <a
                    href="mailto:vidya.raman@roundedlearning.com"
                    title="vidya.raman@roundedlearning.com"
                    target="_blank"
                    className="Footer-business-info-item Footer-business-info-item--email"
                    rel="noreferrer"
                  >
                    vidya.raman@roundedlearning.com
                  </a>
                </div>
                <div className="Footer-business-hours">
                  <div className="Footer-business-hours-heading">Hours</div>
                </div>
              </div>
              <div
                className="sqs-layout sqs-grid-12 columns-12 Footer-blocks Footer-blocks--middle sqs-alternate-block-style-container empty"
                data-layout-label="Footer Middle Blocks"
                data-type="block-field"
                id="footerBlocksMiddle"
              >
                <div className="row sqs-row">
                  <div className="col sqs-col-12" />
                </div>
              </div>
            </div>
            <div
              className="sqs-layout sqs-grid-12 columns-12 Footer-blocks Footer-blocks--bottom sqs-alternate-block-style-container"
              data-layout-label="Footer Bottom Blocks"
              data-type="block-field"
              id="footerBlocksBottom"
            >
              <div className="row sqs-row">
                <div className="col sqs-col-12">
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-2f259caff7c1e37efce9"
                  >
                    <div className="sqs-block-content">
                      <p
                        style={{ textAlign: "center", whiteSpace: "pre-wrap" }}
                      >
                        <strong>
                          ©{new Date().getFullYear()} RoundEd Learning Inc.
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Layout;
