/* eslint-disable no-underscore-dangle */
import { parseSuccessfulResponse, RequestSender } from "./helpers";
import { GoogleCourse } from "~/backendApi/model";
import parseGoogleCourse from "./parseGoogleCourse";

async function getGoogleCourse(
  request: RequestSender,
  id: string,
): Promise<GoogleCourse> {
  const response = await request("get", `/google-courses/${id}`);
  return parseSuccessfulResponse(response, parseGoogleCourse);
}

export default getGoogleCourse;
